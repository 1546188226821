import {
  LineChart,
  Line,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Area,
} from "recharts";
import * as React from "react";
import { Brush } from "recharts";

let xmlData = "";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];


// Declare ClipboardItem interface
interface ClipboardItem {
  new(items: { [mimeType: string]: Blob }): ClipboardItem;
}

declare var ClipboardItem: {
  prototype: ClipboardItem;
  new(items: { [mimeType: string]: Blob }): ClipboardItem;
};


const Chart = (props) => {


  const chartIndex = props.chartIndex ? props.chartIndex : 0;
  const updateXmlData = () => {
    const chartContainer = document.getElementsByClassName("recharts-wrapper")[chartIndex];
    const chartSvg = chartContainer.querySelector("svg");
    const chartXml = new XMLSerializer().serializeToString(chartSvg);
    xmlData = chartXml;
  }

  const handleDownload = () => {

    const chartContainer = document.getElementsByClassName("recharts-wrapper")[chartIndex];
    const chartSvg = chartContainer.querySelector("svg");
    xmlData = xmlData.length >= 0 ? new XMLSerializer().serializeToString(chartSvg) : xmlData;
    const image = new Image();
    const svgBlob = new Blob([xmlData], { type: "image/svg+xml;charset=utf-8" });
    const url = URL.createObjectURL(svgBlob);

    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = chartContainer.clientWidth;
      canvas.height = chartContainer.clientHeight;
      const context = canvas.getContext("2d");

      // Fill the canvas with a white background
      context.fillStyle = "white";
      context.fillRect(0, 0, canvas.width, canvas.height);

      context.drawImage(image, 0, 0);

      const dataUrl = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = dataUrl;

      const name = props.name ? props.name : "chart";
      link.download = `${name}.png`;
      link.click();

      // copy the image to the clipboard
      const img = new Image();
      img.src = dataUrl;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const context = canvas.getContext("2d");
        context.drawImage(img, 0, 0);
        canvas.toBlob(async (blob) => {
          const item = new ClipboardItem({ "image/png": blob });
          const clipboard = navigator.clipboard as any;
          await clipboard.write([item]);
        });
      };
    };

    image.src = url;
  };

  let valueMax = 0;
  let data = props.data.map((x) => {
    if (props.min && props.minFromValue) {
      if (props.min < props.minFromValue) x.minRange = [0, props.min];
      else x.minRange = [props.min, props.minFromValue];
    }
    if (props.max && props.maxFromValue) {
      x.maxRange = [props.max, props.maxFromValue];
    }
    valueMax = Math.max(valueMax, x.value);
    return x;
  });

  console.log(`valueMax: ${valueMax}`);

  const minHeight = props.minHeight ? props.minHeight : 300;
  const minWidth = props.minWidth ? props.minWidth : minHeight * 0.75;
  const brushHeight = props.brushHeight ? props.brushHeight : 20;
  const domainMin = props.domainMin != undefined ? props.domainMin : "auto";
  let domainMax = props.domainMax ? props.domainMax : "auto";

  let halfOfMax = domainMax / 2;
  if (valueMax < halfOfMax) {
    domainMax = halfOfMax;
  }
  // we'll try to reduce the domain to the minimum and maximum values of the data


  const domain = [domainMin, domainMax];

  // if filledZones is defined, then we need to add the filled zones to the chart
  // as areas
  // using the filledZones prop, we can define the zones that we want to fill
  // the max property of each zone defines the upper limit of the filled area
  // the fill property defines the color of the filled area

  let zones = [];
  if (props.filledZones) {
    // we need to add the filledzones to the dataset under the key z_max
    data = props.data.map((x) => {
      // for each data point, we need to add the z_max property
      // which is the maximum value of the filled zones
      let previousMin = 0;
      for (let zone of props.filledZones) {
        // zone.max can't be more than domainMax
        if (zone.max > domainMax) {
          zone.max = domainMax;
        }
        let zoneMaxKey = "z_" + zone.max;
        x[zoneMaxKey] = [previousMin, zone.max];
        previousMin = zone.max;
      }

      return x;
    });


    zones = props.filledZones.map((zone) => {
      let zoneMaxKey = "z_" + zone.max;
      return (
        <Area
          key={zone.max}
          dataKey={zoneMaxKey}
          fill={zone.fill}
          stroke={zone.fill}
          type="monotone"
          yAxisId={0}
          // activeDot={{ r: 8 }}
          isAnimationActive={false}
          connectNulls={true}
        />
      );
    });

    console.log(zones);
  }

  return (
    <>
      <div style={{ flex: "1", width: "100%", textAlign: "right" }} >
        <button onClick={handleDownload} className="button is-link is-outlined is-rounded">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <span className="icon">
                  <i className="fas fa-camera"></i>
                </span>
              </div>
              <div className="level-item">
                Télécharger {props.id}
              </div>
            </div></div>
        </button>
      </div>

      <ResponsiveContainer
        width="100%"
        height="80%"
        minHeight={minHeight}
        minWidth={minWidth}
        onChange={updateXmlData}
        onLoad={updateXmlData}
      >

        <ComposedChart width={minWidth} height={minHeight} data={data}
          id={props.id}
          onClick={updateXmlData}
        >
          <Line
            type="linear"
            dataKey="value"
            strokeWidth={1}
            stroke="black"
            dot={false}
          />


          <CartesianGrid strokeWidth={1} stroke="rgba(0,0,0,0.2)" />

          <XAxis
            dataKey="date"
            tick={{
              strokeWidth: 0.1,
              stroke: "black",

            }}
          />

          <YAxis
            tick={{
              strokeWidth: 0.1,
              stroke: "black",
            }}
            type="number"
            // domain={[0, 3000]}
            domain={domain}
          // range={domain}
          />

          <Tooltip />

          <Brush
            dataKey="date"
            height={brushHeight}
            stroke="#00A78E"
            fill="rgb(0,299,178)"
            onChange={updateXmlData}
          />

          {/* <Area dataKey="minRange" fill="rgba(255,102,102,0.1)" />
          <Area dataKey="maxRange" fill="rgba(255,102,102,0.1)" /> */}
          {zones}

          {props.min ? (
            <ReferenceLine
              y={props.min}
              label={"min"}
              stroke={"red"}
              strokeWidth={1}
            />
          ) : (
            <></>
          )}
          {props.max ? (
            <ReferenceLine
              y={props.max}
              label={"max"}
              stroke={"red"}
              strokeWidth={1}
            />
          ) : (
            <></>
          )}
        </ComposedChart>

      </ResponsiveContainer>
    </>
  );
};
//           <Area dataKey="maxRange" fill="red" />

export default Chart;
